import { axiosGet, axiosDelete, axiosPut, axiosPost, axiosUpload, axiosPostToUrl } from './config'
/**
 * 메인 동영상 업로드
 * @param {*} frmData 
 * @returns 
 */
 export async function upLoadVideo(frmData) {
    try {
      // var url = `lecture/upload`
      var url = `comm/upload/vod`
      let response = await axiosUpload(url, frmData, null)
      return response
    } catch (error) {
      console.log(JSON.stringify(error))
    }
    return null
  }
  /**
   * 동영상 썸네일 이미지 업로드
   * @param {*} frmData 
   * @returns 
   */
  export async function uploadImage(frmData){
    try {
      // var url =`lecture/image/upload`
      var url =`comm/upload/image`
      let resp = await axiosUpload(url, frmData, null)
      return resp;
    } catch (error) {
      console.error(`upload image fail, ${error}`)
    }
  }

  /**
 * 강좌 등록 
 * @param {*} lc_no 
 * @param {*} ca_sn 
 * @param {*} file_path 
 * @param {*} thumb_url 
 * @param {*} content 
 * @param {*} play_second 
 * @param {*} title 
 * @param {*} tags 
 * @param {*} is_cost 
 * @returns 
 */
export async function uploadMainVideo(title, thumb_url, play_second, file_path) {
    try {
      //http://{{adm}}/adm_api/operation/main 
      var url = `operation/main` 
      let resp = await axiosPostToUrl(url,{title, thumb_url, play_second, file_path})
      return resp
    } catch (error) {
      console.log(JSON.stringify(error))
    }
    return null
  }

/**
 * 메인페이지에 표시되는 리스트 가져오기
 */
export  const getMainVideos =async (curPage =1 ,rowPerPage=20)=>{
  // http://{{adm}}/adm_api/operation/main?curPage=1&rowPerPage=30
  try {
    var url =`operation/main?curPage=${curPage}&rowPerPage=${rowPerPage}`
    let resp = await axiosGet(url)
    return resp
  } catch (error) {
    console.error(error)
    alert(error)
  }
}