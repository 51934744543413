<template>
  <div>
    <title-nav :title="'메인Video'" :nav="'메인Video / 메인Video'" />

    <section class="content">
      <b-form>
        <b-row>
          <b-col>
            <b-form-group id="input-group-3" label="영상 제목:" label-for="input-title">
              <b-form-input id="input-title" type="text" v-model="title" required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <video-player
              ref="player"
              :options="videoOptions"
              :configs="{ hls: true }"
              @player-state-changed="playerStateChanged"
            ></video-player>
            <b-form-group
              id="input-group-1"
              label="영상 업로드:"
              label-for="input-video-path"
              description="동영상을 선택하세요"
            >
              <b-form-file
                id="input-video-path"
                accept=".mp4"
                ref="video_file"
                @input="handlueVodInput"
                :state="is_validated_vod"
                required
              ></b-form-file>
              <b-link :href="videoOptions.source.src">{{ videoOptions.source.src }}</b-link>
            </b-form-group>
          </b-col>
          <b-col>
            <b-img :src="mThumbnail_path" style="width:50%;" />
            <b-form-group
              id="input-group-2"
              label="영상 썸네일:"
              label-for="input-thumbnail-path"
              description="PNG 형식의 썸네일을 선택하세요"
            >
              <b-form-file
                id="input-thumbnail-path"
                accept="image/png"
                ref="image_file"
                @change="previewImage"
                :state="is_validated_img"
                required
              ></b-form-file>
              <b-link :href="mThumbnail_path">{{ mThumbnail_path }}</b-link>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row> </b-row>

        <b-form-group id="input-group-6">
          <b-button class="m-1" variant="dark" @click.prevent="clear"> 취소</b-button>
          <b-button class="m-1" variant="primary" @click.prevent="upload">등록</b-button>
        </b-form-group>
      </b-form>
    </section>
    <section>
      <b-table
        striped
        hover
        bordered
        class="table-sm text-sm-center"
        :borderless="borderless"
        :dark="dark"
        :fixed="fixed"
        :no-border-collapse="noCollapse"
        :items="items"
        :fields="fields"
        :head-variant="headVariant"
        :busy="isBusy"
        show-empty
      >
        <template>
          <div></div>
        </template>
      </b-table>
    </section>
  </div>
</template>

<script>
import { uploadMainVideo, upLoadVideo, uploadImage, getMainVideos } from '@/api/mainvideo'
import { videoPlayer } from 'vue-vjs-hls'

export default {
  name: 'mainvideo',
  components: { videoPlayer },
  data() {
    return {
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      mThumbnail_path: '',
      mThumbnail: '',
      curPage: 1,
      rowPerPage: 20,
      isBusy: false,
      is_validated_vod: null,
      is_validated_img: null,
      vod: null,
      img: '',
      vod_server_path: '',
      title: '',
      videoOptions: {
        source: {
          type: 'application/x-mpegURL',
          src: '',
          // if you need custom player state changed event name, you can config it like this
          customEventName: 'my-player-state-changed-event-custom-name',
          withCredentials: false
        },
        live: true
      },
      items: [],

      fields: [
        {
          key: 'video_sn',
          label: 'No.',
          colType: 'span'
        },
        {
          key: 'play_second',
          label: '길이(초)',
          colType: 'span'
        },
        {
          key: 'title',
          label: '제목',
          colType: 'span'
        },
        {
          key: 'use_yn',
          label: '사용 여부',
          colType: 'span'
        },
        {
          key: 'thumb_url',
          label: 'thumb_url',
          colType: 'span'
        },
        {
          key: 'file_path',
          label: 'file_path',
          colType: 'span'
        }
      ]
    }
  },
  computed: {},
  methods: {
    clear() {
      location.href = '/edit'

      // this.newLectId = ''
      // this.is_validated_lc_no = null
      // this.is_validated_vod = null
      // this.lecture_no = ''
      // this.mThumbnail_path = ''
      // this.mainProps = { blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' }
      // ;(this.tags = []), (this.title = '')
      // this.title = ''
      // this.$refs.video_file = null
    },
    playerStateChanged(playerCurrentState) {
      console.log(playerCurrentState)
    },
    handlueVodInput(e) {
      console.log(e)
      console.log(this.vod)
      console.log(this.$refs.video_file.files[0])
      var file = this.$refs.video_file.files[0]
      var frmData = new FormData()
      frmData.append('file', file)

      upLoadVideo(frmData)
        .then(_resp => {
          this.is_validated_vod = true
          this.vod_server_path = _resp.data.file_path
          console.log(this.server_url)
          // var url = `${this.server_url}${this.vod_server_path}`
          // console.log(url)
          this.videoOptions.source.src = this.vod_server_path
        })
        .catch(_error => {
          this.is_validated_vod = false
          console.error(_error)
        })
    },
    previewImage(evt) {
      if (evt.target.files && evt.target.files[0]) {
        console.log(evt.target.files[0])
        var frmData = new FormData()
        var file = evt.target.files[0]
        frmData.append('file', file)
        uploadImage(frmData)
          .then(resp => {
            this.mThumbnail_path = resp.data.url_path
            this.is_validated_img = true
          })
          .catch(err => {
            console.error(err)
            alert(`동영상 등록 실패${err}`)
          })
      } else {
        this.mThumbnail_path = null
      }
    },
    // getContent(content) {},
    upload() {
      // var lc_no = `A${this.lecture_no}`

      if (!this.is_validated_vod) {
        alert('동영상을 등록하세요')
        return
      }
      if (!this.is_validated_img) {
        alert('썸네일을 등록하세요')
        return
      }
      if (!this.title) {
        alert(`강좌명을 입력하세요`)
        return
      }
      var video_path = this.vod_server_path
      var thumb_url = this.mThumbnail_path
      var play_second = this.$refs['player'].player.duration()
      var title = this.title

      uploadMainVideo(title, thumb_url, play_second, video_path)
        .then(_resp => {
          if (_resp.code !== '0000') {
            alert(`등록 실패: ${_resp.message}`)
          } else {
            alert('등록되었습니다.')
            location.href = '/edit'
          }
        })
        .catch(_err => {
          console.log(_err)
        })
    },
    async init() {
      const resp = await getMainVideos(this.curPage, this.rowPerPage)
      console.log(resp)
      if (resp.code === '0000') {
        this.items = resp.data.list
      } else {
        alert(`error:${resp.code}`)
      }
    }
  },
  async mounted() {
    this.init()
  }
}
</script>

<style>
@import 'https://unpkg.com/vue-plyr@latest/dist/vue-plyr.css';
</style>
